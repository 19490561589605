export const allowAnalytics = () => {
    if (window == undefined) {
        return
    }
    // Add Google Analytics
    (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date();
        a = s.createElement(o), m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js',
        'ga');

    window.ga('create', 'UA-9721080-9', 'auto');
    window.ga('set', 'anonymizeIp', true);
    window.ga('send', 'pageview');
    // End Google Analytics
}

export const disallowAnalytics = () => {
    if (window == undefined) {
        return
    }
    // Disable Google Analytics
    window['ga-disable-UA-9721080-9'] = true;
    // End Google Analytics
}