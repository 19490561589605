import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Gallery from "../../../../src/components/layout/gallery";
import SingleImage from "../../../../src/components/layout/single-image";
import LightboxPageLayout from "../../../../src/components/layout/lightbox-page-layout";
import * as React from 'react';
export default {
  graphql,
  Link,
  GatsbyImage,
  getImage,
  Gallery,
  SingleImage,
  LightboxPageLayout,
  React
};