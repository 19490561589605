let timeout: NodeJS.Timeout | null = null
const fbReload = () => {
    if (timeout != null) {
        clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
        if (FB) {
            console.log('reloading')
            FB.XFBML.parse()
        }
    }, 500)
}

export const allowSocial = () => {
    // Enable fb jssdk
    const fbSdk = document.getElementById('facebook-sdk')
    const fbDiv = document.getElementById('fb-root')

    if (!fbSdk) {
        const script = document.createElement('script')
        script.id = 'facebook-sdk'
        script.type = 'text/javascript'
        script.crossOrigin = 'anonymous'
        script.defer = true
        script.nonce = 'UiBlPijC'
        script.src = '//connect.facebook.net/hu_HU/all.js#xfbml=1'
        script.onload = () => {
            fbReload()
        }
        fbDiv?.after(script)
    }

    const warning = document.getElementById('facebook-cookie-warning')
    warning?.classList.add('hidden')
    
    window.addEventListener('resize', fbReload)
}

export const disallowSocial = () => {
    // Disable fb jssdk
    const fbSdk = document.getElementById('facebook-sdk')

    if (fbSdk != null) {
        fbSdk.parentElement?.removeChild(fbSdk)
    }

    const warning = document.getElementById('facebook-cookie-warning')
    warning?.classList.remove('hidden')

    /* 
    fbDiv?.removeAttribute('fb-iframe-plugin-query')
    fbDiv?.classList.remove('fb_iframe_widget') */
    const fbDiv = document.getElementById('facebook')
    fbDiv?.removeAttribute('fb-xfbml-state')
    const fbIframe = document.querySelector('#facebook span')
    if (fbIframe != null) {
        const bq = document.createElement('blockquote')
        bq.cite = 'https://www.facebook.com/facebook'
        bq.classList.add('fb-xfbml-parse-ignore')
        bq.innerHTML = '<a href="https://www.facebook.com/facebook">Meta</a>'
        fbIframe.after(bq)
        fbIframe.parentElement?.removeChild(fbIframe)
    }
    
    window.removeEventListener('resize', fbReload)
}