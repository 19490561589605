import * as React from 'react'

import Cookie from '../../images/cookie.svg'

type ButtonProps = {
    onClick: () => void
}
const CookieButton: React.FC<ButtonProps> = ({ onClick }) => {

    const buttonRef = React.useRef<HTMLDivElement>(null)
    const ghostDivRef = React.useRef<HTMLDivElement>(null)
    const [isIntersecting, setIsIntersecting] = React.useState(true)

    const checkIntersection = () => {
        if (buttonRef.current && ghostDivRef.current) {
            const ghostRect = ghostDivRef.current.getBoundingClientRect()        
            const buttonRect = buttonRef.current.getBoundingClientRect()
            const rectsAreIntersecting = (buttonRect.x + buttonRect.width) > (ghostRect.x + 5)
            setIsIntersecting(rectsAreIntersecting)
        }
    }

    React.useEffect(() => {
        checkIntersection()
        window.addEventListener('resize', checkIntersection)

        return () => {
            window.removeEventListener('resize', checkIntersection)
        }
    }, [])

    const baseButtonClass = `fixed bottom-0 left-0 group flex justify-center items-end
        bg-[#00648C] hover:bg-[#0F6D92] text-white rounded-tr-full border-indigo-900 shadow`
    const smallButtonClass = baseButtonClass + ` w-10 h-10 hover:w-24 hover:h-24`
    const largeButtonClass = baseButtonClass + ` w-24 h-24`
    const smallIconClass = `w-5 h-5 group-hover:w-12 group-hover:h-12
        mr-1 mb-2 group-hover:mr-3 group-hover:mb-4 fill-white`
    const largeIconClass = `w-12 h-12 mr-3 mb-4 fill-white`

    return (
        <>
        <div className="fixed bottom-0 h-24 w-full bg-gray-300 invisible" aria-hidden="true">
            <div ref={ buttonRef } className={ largeButtonClass }></div>
            <div className="container h-full mx-auto px-4">
                <div className="bg-white h-full" ref={ ghostDivRef }></div>
            </div>
        </div>
        <button className={ isIntersecting ? smallButtonClass : largeButtonClass }
            aria-label="Sütik kezelése" onClick={ onClick }>
            <Cookie className={ isIntersecting ? smallIconClass : largeIconClass } />
        </button>
        </>
    )
}

export default CookieButton