import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ImageNodes } from '../types'

import data from '../../images/banner/data.json'

type BannerQuery = {
    allFile: {
        nodes: ImageNodes
    }
}
const Carousel = () => {

    const { allFile: { nodes } } = useStaticQuery<BannerQuery>(graphql`
        query BannerQuery {
            allFile(
                filter: {
                    relativeDirectory: { eq: "banner" },
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" } }
            ) {
                nodes {
                    base
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: TRACED_SVG
                            layout: FULL_WIDTH
                            breakpoints: [640, 768, 1024, 1280, 1628, 3256]
                        )
                    }
                }
            }
        }
    `)

    const [currentIndex, setCurrentIndex] = React.useState(0)

    React.useEffect(() => {
        const next = (currentIndex + 1) % nodes.length
        const interval = setTimeout(() => setCurrentIndex(next), 3000)
        return () => clearInterval(interval)
    }, [currentIndex])

    const baseClass = 'absolute inset-0 transition-opacity duration-1000'
    const currentClass = baseClass + ' opacity-100'
    const notcurrentClass = baseClass + ' opacity-0'
    
    return (
        <section className="relative" role="presentation" aria-live="off"
            aria-roledescription="carousel" aria-label="Noé Életotthon adó 1% bannerek">
            <div className="bg-gray-300 aspect-[960/453]">&nbsp;</div>
            {
                data.resources.map((resource, index) => {
                    const image = nodes.filter(({ base }) => base == resource.image)[0]
                    return (
                        <div key={ index } className={ index == currentIndex ?
                            currentClass : notcurrentClass }
                            role="group" aria-roledescription="slide"
                            aria-label={ (index + 1) + ' of ' + nodes.length }>
                            <GatsbyImage
                                image={ image.childImageSharp.gatsbyImageData }
                                alt={ resource.title } />
                        </div>
                    )
                })
            }
        </section>
)}

export default Carousel