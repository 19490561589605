import * as React from 'react'

import { allowYoutube, disallowYoutube } from './youtube'
import { allowSocial, disallowSocial } from './social'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { allowAnalytics, disallowAnalytics } from './analytics.js'

type cookieState = {
    analytics: boolean,
    youtube: boolean,
    social: boolean,
}

export const useCookies = () => {

    const [analytics, setAnalytics] = React.useState(false)
    const [youtube, setYoutube] = React.useState(false)
    const [social, setSocial] = React.useState(false)

    const storeCookieState = () => {
        localStorage.setItem('noecookiestate', JSON.stringify({
            analytics,
            youtube,
            social,
        }))
    }

    React.useEffect(() => {
        const lsState: cookieState = (localStorage.getItem('noecookiestate') != null) ?
            JSON.parse(localStorage.getItem('noecookiestate') || '') : {
                analytics: false,
                youtube: false,
                social: false,
            }
        if (lsState.analytics != null) {
            setAnalytics(lsState.analytics)
        }
        if (lsState.youtube != null) {
            setYoutube(lsState.youtube)
        }
        if (lsState.social != null) {
            setSocial(lsState.social)
        }
    }, [])

    React.useEffect(() => {
        if (analytics) {
            allowAnalytics()
        } else {
            disallowAnalytics()
        }
        storeCookieState()
    }, [analytics])
    
    React.useEffect(() => {
        if (youtube) {
            allowYoutube()
        } else {
            disallowYoutube()
        }
        storeCookieState()
    }, [youtube])
    
    React.useEffect(() => {
        if (social) {
            allowSocial()
        } else {
            disallowSocial()
        }
        storeCookieState()
    }, [social])

    return {
        cookieState: {
            analytics,
            setAnalytics,
            youtube,
            setYoutube,
            social,
            setSocial,
        }
    }
}