import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { useCookies } from '../cookies/cookie-state'
import Header from './header'
import Bottom from './bottom'
import Footer from './footer'
import CookieConsent from '../cookies/cookie-consent'

type LayoutProps = {
    children: React.ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
    type MetaDataQuery = {
        site: {
            siteMetadata: {
                menuLinks: {
                    label: string,
                    link: string,
                }[]
            }
        }
    }
    const data = useStaticQuery<MetaDataQuery>(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    menuLinks {
                        label
                        link
                    }
                }
            }
        }
    `)

    const { cookieState } = useCookies()

    const [isSafari, setIsSafari] = React.useState(false)
    /* safari fix */
    React.useEffect(() => {
        //const checkIsSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
        const checkIsSafari = navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("iPhone") != -1
        setIsSafari(checkIsSafari)
        if (checkIsSafari) {
            document.body.classList.add('safari')
        } else {
            document.body.classList.remove('safari')
        }
    }, [])
    const divClass = `min-h-screen flex flex-col bg-gray-100` //+ (isSafari ? ' safari' : '')

    return (
        <div className={ divClass }>
            <Header menuLinks={ data.site.siteMetadata.menuLinks }/>
            <main id="main-content" className="grow container mx-auto md:px-4">
                { children }
                <Bottom />
            </main>
            <Footer menuLinks={ data.site.siteMetadata.menuLinks } />
            <CookieConsent cookieState={ cookieState } />
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
