import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { ImageResources, ImageNodes } from '../types'

type SingleImageProps = {
    resources: ImageResources,
    nodes: ImageNodes,
}
const SingleImage: React.FC<SingleImageProps> = ({ resources, nodes }) => (
    <div className="w-full max-w-[526px] my-4 mx-auto">
        { resources.map((resource, index) => {
            const image = nodes.filter(({ base }) => base == resource.image.base)[0]        
            return (
                <GatsbyImage
                    key={ index }
                    image={ image.childImageSharp.gatsbyImageData }
                    alt={ resource.title }
                />
            )
        }) }
    </div>
)

export default SingleImage