import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = function Layout({
  children
}) {
  return <>{children}</>;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hogyan rendelkezhet adója 1 százalékáról? (Forrás: `}<a href="https://nav.gov.hu/ado/szja1_1/11-os-rendelkezes-2024-ben/" target="_blank" rel="noreferrer">{`nav.gov.hu`}</a>{`)`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Idén is lehet rendelkezni a személyi jövedelemadó 1+1%-áról legkésőbb 2024. május 21-éig, az szja-bevallástól függetlenül is.`}</strong></p>
    <p>{`Bárki dönthet úgy idén is, hogy az összevont adóalap után befizetett személyi jövedelemadójának 1 százalékát felajánlja egy regisztrált civilszervezetnek, további 1 százalékát pedig egy technikai számos vallási közösségnek`}{`[1]`}{` vagy a kiemelt költségvetési előirányzatnak, ami idén a Nemzeti Tehetség Program.`}</p>
    <p><strong parentName="p">{`A felajánlásokra jogosult, kedvezményezett civilszervezetek listája `}<a href="https://nav.gov.hu/ado/szja1_1/felajanlasra-jogosultak/regisztralt_civil" target="_blank" rel="noreferrer">{`itt érhető el`}</a>{`.`}</strong></p>
    <p><strong parentName="p">{`A felajánlásokra jogosult vallási közösségek listája és a kiemelt előirányzat `}<a href="https://nav.gov.hu/ado/szja1_1/felajanlasra-jogosultak/egyszaz_tszam" target="_blank" rel="noreferrer">{`itt érhető el`}</a>{`.`}</strong></p>
    <p>{`Az szja 1+1%-áról - függetlenül az szja-bevallás módjától - a bevallással együtt vagy külön is lehet rendelkezni.`}</p>
    <p>{`A nyilatkozat legegyszerűbben `}<strong parentName="p">{`elektronikusan`}</strong>{` nyújtható be`}</p>
    <ul>
      <li parentName="ul">{`az `}<a href="https://eszja.nav.gov.hu/app/login/#login" target="_blank" rel="noreferrer">{`e-SZJA webes felületen`}</a>{`, online kitöltve vagy`}</li>
      <li parentName="ul">{`az ÁNYK-ban a bevallással együtt, annak EGYSZA lapját is kitöltve vagy`}</li>
      <li parentName="ul">{`külön, a 23EGYSZA nyomtatvány kitöltésével.`}</li>
    </ul>
    <p><strong parentName="p">{`Papíralapon`}</strong>{` benyújtható postán keresztül, vagy személyesen, illetve meghatalmazott által, lezárt borítékban a NAV ügyfélszolgálatain`}</p>
    <ul>
      <li parentName="ul">{`a személyijövedelemadó-bevallással együtt, annak EGYSZA lapját is kitöltve,`}</li>
      <li parentName="ul">{`az e-SZJA felület kitöltőprogramjával készített és kinyomtatott nyilatkozaton,`}</li>
      <li parentName="ul">{`a 23EGYSZA jelű nyomtatványon vagy azonos adatokkal a nyilatkozaton, továbbá`}</li>
      <li parentName="ul">{`2024`}{`.`}{` május 10-ig lezárt borítékban leadva a munkáltatónak, ha az vállalja a nyilatkozatok összegyűjtését. A borítékon a leragasztás helyén szerepelnie kell a felajánló aláírásának.`}</li>
    </ul>
    <p>{`Ha a nyilatkozatokat eseti meghatalmazott nyújtja be, csatolni kell a meghatalmazást is.`}</p>
    <p><strong parentName="p">{`A 2024. május 21-ei határidő jogvesztő.`}</strong>{` Ez azt jelenti, hogy ha valaki május 21. után rendelkezik befizetett személyi jövedelemadója 1+1%-áról, a rendelkezés érvénytelen, és a késedelem igazolási kérelemmel sem menthető ki.`}</p>
    <p>{`2024.január 1-jétől már nem csak a vallási közösségeknek, hanem a kiemelt költségvetési előirányzatnak (jelenleg a `}<strong parentName="p">{`Nemzeti Tehetség Programnak`}</strong>{`) `}<strong parentName="p">{`tett felajánlások is érvényben maradnak`}</strong>{`. Ez azt jelenti, hogy a 2018-ban (a kiemelt költségvetési előirányzatra nézve 2021-ben) és azt követő években technikai számra tett, formailag megfelelő nyilatkozatokat a NAV 2024-ben és utána is figyelembe veszi, mindaddig, amíg a rendelkező nem ad be újabb nyilatkozatot vagy korábbi nyilatkozatát nem vonja vissza.`}</p>
    <p>{`2024.január 1-jétől nem vehető figyelembe a technikai számra tett, formailag megfelelő nyilatkozat akkor sem, ha a magánszemély az adóévet követő év május 20-áig - a 2024-es rendelkezési évben 2024. május 21-ig - nem nyújtott be személyijövedelemadó-bevallást vagy a benyújtott bevallása alapján nem keletkezett adóköteles, összevonás alá eső jövedelme.`}</p>
    <p><strong parentName="p">{`A civil kedvezményezettek javára`}</strong>{` ugyanakkor továbbra is `}<strong parentName="p">{`évenként kell rendelkezni`}</strong>{`, mivel ezeket a felajánlásokat csak egy alkalommal, a rendelkezés évében veheti figyelembe a NAV.`}</p>
    <p>{`Az szja 1+1%-áról rendelkező dönthet úgy, hogy a NAV közölje nevét és elérhetőségét a megjelölt civil kedvezményezettel. Ezeknek az adatoknak a megadása és az adatkezelési hozzájárulás önkéntes, tehát nem feltétele az érvényes rendelkezésnek.`}</p>
    <p>{`2024.januárjától a rendelkező a NAV honlapján azt is megtekintheti, hogy az idén megjelölt kedvezményezett részesült-e a felajánlott összegből. Ha elektronikus tárhelye is van, a NAV elektronikusan is tájékoztatja a felajánlott összeg kiutalásáról.`}</p>
    <hr></hr>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      