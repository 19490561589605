import * as React from 'react'
import { Dialog, Switch } from '@headlessui/react'

import Close from '../../images/close.svg'

type SidebarProps = {
    onClick: () => void,
    isOpen: boolean,
    cookieState: {
        analytics: boolean,
        setAnalytics: React.Dispatch<React.SetStateAction<boolean>>,
        youtube: boolean,
        setYoutube: React.Dispatch<React.SetStateAction<boolean>>,
        social: boolean,
        setSocial: React.Dispatch<React.SetStateAction<boolean>>,
    }
}
const CookieSidebar: React.FC<SidebarProps> = ({ onClick, isOpen, cookieState }) => {

    /* const [analytics, setAnalytics] = React.useState(false)
    const [youtube, setYoutube] = React.useState(false)
    const [social, setSocial] = React.useState(false) */

    const switchBgClass = `relative inline-flex items-center h-6 rounded-full w-11`
    const switchClass = `inline-block w-4 h-4 transform bg-[#86C3E2] group-hover:bg-sky-200 rounded-full`

    return (
        <Dialog as="div" onClose={ onClick } open={ isOpen }>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            <div className="fixed z-10 left-0 top-0 bottom-0
            w-full h-full md:w-2/3 lg:w-1/2 xl:w-1/3 3xl:w-1/4 p-6
            bg-white shadow overflow-y-auto">
            <button className="absolute top-5 right-5"
                onClick={ onClick }
                aria-label="Sütibeállítások bezárása">
                <Close className="w-5 h-5" />
            </button>
            <Dialog.Title className="py-2 text-xl font-bold">A weboldal sütiket (cookie-kat) használ.</Dialog.Title>
            <Dialog.Description as="div">
                <p className="py-2">Bizonyos sütik az oldal működéséhez szükségesek, mások a felhasználói élmény javításához járulnak hozzá.</p>
                <p className="py-2">További információkért keresse fel <a
                    className="underline text-blue-700 hover:text-blue-500"
                    target="_blank" rel="noreferrer"
                    href="https://noeallatotthon.hu/adatkezeles">adatkezelési leírásunkat</a>.
                </p>
            </Dialog.Description>
            <div className="my-4 py-4 border-t-2 border-gray-300">
                <div className="py-2 text-xl font-bold">Szükséges sütik</div>
                <div>A szükséges sütik az oldal alapvető működéséhez kellenek. Csak a böngésző beállításainak módosításával lehet ezeket eltávolítani.</div>
            </div>
            <div className="my-4 py-4 border-t-2 border-gray-300">
                <div className="flex flex-wrap justify-between content-center py-2">
                    <div className="text-xl font-bold pr-4">Statisztikai sütik</div>
                    <Switch.Group>
                        <div className="flex items-center group">
                            <Switch.Label className="mr-4">{ cookieState.analytics ? 'Be' : 'Ki' }</Switch.Label>
                            <Switch
                                checked={ cookieState.analytics }
                                onChange={ cookieState.setAnalytics }
                                className={ (cookieState.analytics ? `bg-[#00648C] ` : `bg-gray-300 `) + switchBgClass }>
                                <span className="sr-only">Analitikai sütik engedélyezése</span>
                                <span className={ (cookieState.analytics ? `translate-x-6 ` : `translate-x-1 `) + switchClass } />
                            </Switch>
                        </div>
                    </Switch.Group>
                </div>
                <div>A statisztikai adatok gyűjtése oldalunk fejlesztésében segít.</div>
            </div>
            <div className="my-4 py-4 border-t-2 border-gray-300">
                <div className="flex flex-wrap justify-between content-center py-2">
                    <div className="text-xl font-bold pr-4">Youtube sütik</div>
                    <Switch.Group>
                        <div className="flex items-center group">
                            <Switch.Label className="mr-4">{ cookieState.youtube ? 'Be' : 'Ki' }</Switch.Label>
                            <Switch
                                checked={ cookieState.youtube }
                                onChange={ cookieState.setYoutube }
                                className={ (cookieState.youtube ? `bg-[#00648C] ` : `bg-gray-300 `) + switchBgClass }>
                                <span className="sr-only">Analitikai sütik engedélyezése</span>
                                <span className={ (cookieState.youtube ? `translate-x-6 ` : `translate-x-1 `) + switchClass } />
                            </Switch>
                        </div>
                    </Switch.Group>
                </div>
                <div>Videók megjelenítése során külső szolgáltató (Youtube) sütijei kerülnek mentésre.
                    Ezek nélkül a youtube videók nem láthatóak.</div>
            </div>
            <div className="my-4 py-4 border-t-2 border-gray-300">
                <div className="flex flex-wrap justify-between content-center py-2">
                    <div className="text-xl font-bold pr-4">Közösségi média sütik</div>
                    <Switch.Group>
                        <div className="flex items-center group">
                            <Switch.Label className="mr-4">{ cookieState.social ? 'Be' : 'Ki' }</Switch.Label>
                            <Switch
                                checked={ cookieState.social }
                                onChange={ cookieState.setSocial }
                                className={ (cookieState.social ? `bg-[#00648C] ` : `bg-gray-300 `) + switchBgClass }>
                                <span className="sr-only">Analitikai sütik engedélyezése</span>
                                <span className={ (cookieState.social ? `translate-x-6 ` : `translate-x-1 `) + switchClass } />
                            </Switch>
                        </div>
                    </Switch.Group>
                </div>
                <div>Cikkek megosztása során külső szolgáltató (Facebook) sütijei kerülnek mentésre.
                    Ezek nélkül a megosztás, kedvelés és idővonal megjelenítése funkciók nem működnek.</div>
            </div>
            </div>
        </Dialog>
    )
}

export default CookieSidebar