import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Dialog } from '@headlessui/react'

import Close from '../../images/close.svg'
import ChevronLeft from '../../images/chevron-left.svg'
import ChevronRight from '../../images/chevron-right.svg'
import { ImageResources, ImageNodes } from '../types'

type LightboxProps = {
    isOpen: boolean,
    onClose: () => void,
    onPrevious: () => void,
    onNext: () => void,
    resources: ImageResources,
    nodes: ImageNodes,
    selectedIndex: number,
}
const Lightbox: React.FC<LightboxProps> = ({ isOpen, onClose, onPrevious, onNext, resources, nodes, selectedIndex }) => {

    const getImage = () => nodes.filter(({ base }) => base == resources[selectedIndex].image.base)[0]

    const [image, setImage] = React.useState(getImage())

    const [containerStyle, setContainerStyle] = React.useState({
        maxWidth: image.childImageSharp.original?.width + 'px',
        maxHeight: image.childImageSharp.original?.height + 'px',
    })

    const setMaxSizes = () => {
        if (image.childImageSharp.original == undefined) {
            return
        }

        if (typeof window !== 'undefined') {
            const width = window.innerWidth
            const height = window.innerHeight

            const constrainedWidth = Math.min(
                image.childImageSharp.original!.width,
                width - (2 * 48),
                (height - (2 * 48)) * (image.childImageSharp.original!.width / image.childImageSharp.original!.height)
            )
            const constrainedHeight = constrainedWidth / (image.childImageSharp.original!.width / image.childImageSharp.original!.height)

            setContainerStyle({
                maxWidth: Math.round(constrainedWidth) + 'px',
                maxHeight: Math.round(constrainedHeight) + 'px',
            })
        }
    }

    React.useEffect(() => {
        setImage(getImage())
    }, [selectedIndex])

    React.useEffect(() => {
        setMaxSizes()
        window.addEventListener('resize', setMaxSizes)

        return () => {
            window.removeEventListener('resize', setMaxSizes)
        }
    }, [image])

    return (
        <Dialog as="div" onClose={ onClose } open={ isOpen }
            className="fixed inset-0 p-12 flex flex-col justify-center items-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            <button className="absolute top-5 right-5 text-white"
                onClick={ onClose }
                aria-label="Képnagyítás bezárása">
                <Close className="w-5 h-5" />
            </button>
            <button className="absolute top-1/2 left-4 text-white"
                onClick={ onPrevious }
                aria-label="Előző kép a galériában">
                <ChevronLeft className="w-8 h-8" />
            </button>
            <button className="absolute top-1/2 right-4 text-white"
                onClick={ onNext }
                aria-label="Következő kép a galériában">
                <ChevronRight className="w-8 h-8" />
            </button>
            <div
                className="relative w-full h-full p-2 bg-white rounded shadow z-10"               
                style={ containerStyle }
            >
                <GatsbyImage
                    image={ image.childImageSharp.gatsbyImageData }
                    alt={ resources[selectedIndex].title }
                    className="w-full h-full bg-white rounded-sm"
                />
                <Dialog.Title className="absolute bottom-2 left-2 right-2 p-3
                    bg-black/40 text-white text-base font-bold">
                    { resources[selectedIndex].title }
                    <span className="font-normal"> ({ selectedIndex + 1 }/{ nodes.length })</span>
                </Dialog.Title>
            </div>
        </Dialog>
    )
}

export default Lightbox