import * as React from 'react'

import CookieButton from './cookie-button'
import CookieSidebar from './cookie-sidebar'

type CookieConsentProps = {
    cookieState: {
        analytics: boolean,
        setAnalytics: React.Dispatch<React.SetStateAction<boolean>>,
        youtube: boolean,
        setYoutube: React.Dispatch<React.SetStateAction<boolean>>,
        social: boolean,
        setSocial: React.Dispatch<React.SetStateAction<boolean>>,
    }
}
const CookieConsent: React.FC<CookieConsentProps> = ({ cookieState }) => {

    const [isVisible, setIsVisible] = React.useState(false)

    const onClick = () => {
        setIsVisible(prevState => !prevState)
    }

    return (
        <>
        { isVisible ? (
            <CookieSidebar onClick={ onClick } isOpen={ isVisible } cookieState={ cookieState } />
            ) : (
            <CookieButton onClick={ onClick } />
        ) }
        </>
    )
}

export default CookieConsent