export const allowYoutube = () => {
    if (document == undefined) {
        return
    }
    const iframes = document.querySelectorAll('iframe.youtube')

    iframes.forEach(iframe => {
        const src = iframe.getAttribute('data-src')
        if (src) {
            iframe.classList.remove('hidden')
            iframe.setAttribute('src', src)
        }

        const warning = document.getElementById('youtube-cookie-warning')
        warning?.classList.add('hidden')
    })
}

export const disallowYoutube = () => {
    if (document == undefined) {
        return
    }
    const iframes = document.querySelectorAll('iframe.youtube')

    iframes.forEach(iframe => {
        const src = iframe.getAttribute('src')
        if (src) {
            iframe.classList.add('hidden')
            iframe.setAttribute('src', '')
        }

        const warning = document.getElementById('youtube-cookie-warning')
        warning?.classList.remove('hidden')
    })
}