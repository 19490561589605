exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ado-1-nyilatkozat-tsx": () => import("./../../../src/pages/ado-1-nyilatkozat.tsx" /* webpackChunkName: "component---src-pages-ado-1-nyilatkozat-tsx" */),
  "component---src-pages-erre-forditjuk-2018-mdx": () => import("./../../../src/pages/erre-forditjuk-2018.mdx" /* webpackChunkName: "component---src-pages-erre-forditjuk-2018-mdx" */),
  "component---src-pages-erre-forditjuk-2019-mdx": () => import("./../../../src/pages/erre-forditjuk-2019.mdx" /* webpackChunkName: "component---src-pages-erre-forditjuk-2019-mdx" */),
  "component---src-pages-erre-forditjuk-2020-mdx": () => import("./../../../src/pages/erre-forditjuk-2020.mdx" /* webpackChunkName: "component---src-pages-erre-forditjuk-2020-mdx" */),
  "component---src-pages-erre-forditjuk-2021-mdx": () => import("./../../../src/pages/erre-forditjuk-2021.mdx" /* webpackChunkName: "component---src-pages-erre-forditjuk-2021-mdx" */),
  "component---src-pages-erre-forditjuk-2022-mdx": () => import("./../../../src/pages/erre-forditjuk-2022.mdx" /* webpackChunkName: "component---src-pages-erre-forditjuk-2022-mdx" */),
  "component---src-pages-erre-forditjuk-mdx": () => import("./../../../src/pages/erre-forditjuk.mdx" /* webpackChunkName: "component---src-pages-erre-forditjuk-mdx" */),
  "component---src-pages-hogyan-tamogathat-mdx": () => import("./../../../src/pages/hogyan-tamogathat.mdx" /* webpackChunkName: "component---src-pages-hogyan-tamogathat-mdx" */),
  "component---src-pages-igy-ajanld-fol-mdx": () => import("./../../../src/pages/igy-ajanld-fol.mdx" /* webpackChunkName: "component---src-pages-igy-ajanld-fol-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kapcsolat-mdx": () => import("./../../../src/pages/kapcsolat.mdx" /* webpackChunkName: "component---src-pages-kapcsolat-mdx" */),
  "component---src-pages-letoltheto-anyagok-tsx": () => import("./../../../src/pages/letoltheto-anyagok.tsx" /* webpackChunkName: "component---src-pages-letoltheto-anyagok-tsx" */),
  "component---src-pages-technikai-szam-mdx": () => import("./../../../src/pages/technikai-szam.mdx" /* webpackChunkName: "component---src-pages-technikai-szam-mdx" */)
}

