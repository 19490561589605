import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

import Carousel from './carousel'
import Menu from '../../images/menu.svg'
import Close from '../../images/close.svg'

type MenuLinkProps = {
    isVisible: boolean
    to: string
    children: React.ReactNode
}
const MenuLink: React.FC<MenuLinkProps> = ({ to, children, isVisible }) => {
    const visible = isVisible ? 'flex' : 'hidden'
    const baseClass = visible + ` md:flex w-full md:w-auto px-2 grow
        md:justify-center md:items-center md:text-center leading-10 md:leading-normal`
    
    return (
        <Link to={ to } getProps={ ({ isCurrent }) => {
            return isCurrent
                ? {
                      className: baseClass + ' border-b-4 border-[#55a9d3] -mb-1',
                  }
                : {
                      className: baseClass + ' hover:bg-white/10',
                  }
        } }>
            { children }
        </Link>
    )
}
type SubMenuLinkProps = {
    to: string
    children: React.ReactNode
}
const SubMenuLink: React.FC<SubMenuLinkProps> = ({ to, children }) => {    
    return (
        <Link to={ to } getProps={ ({ isCurrent }) => {
            return isCurrent
                ? {
                      className: 'p-4 underline',
                  }
                : {
                      className: 'p-4 hover:bg-white/10',
                  }
        } }>
            { children }
        </Link>
    )
}

type HeaderProps = {
    menuLinks: {
        label: string,
        link: string,
    }[]
}
const Header: React.FC<HeaderProps> = ({ menuLinks }) => {

    const [menuOpen, setMenuOpen] = React.useState(false)

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        setMenuOpen(prevState => !prevState)
    }

    const { pathname } = useLocation()

    return (
        <header>
            <div className="bg-gray-300 border-t-8 border-[#03628f]">
                <div className="md:container mx-auto md:px-4">
                    <Carousel />
                </div>
            </div>
            <div className="w-full md:container md:mx-auto px-4
                bg-[#0589c9] border-b-8 border-[#00648C]
                md:bg-transparent md:border-none">
                <nav aria-label="Menü"
                    className="container mx-auto flex flex-wrap md:flex-nowrap justify-between items-stretch md:h-16
                    text-lg md:text-xs font-normal md:font-bold uppercase text-white
                    md:bg-gradient-to-b md:from-[#003a5c] md:to-[#0582c2]"
                >
                    <button onClick={ onClick }
                        className="w-full md:hidden px-2 leading-10 flex grow hover:bg-white/10"
                        aria-label={ menuOpen ? 'Menü bezárása' : 'Menü megjelenítése' }>
                        { menuOpen ? (
                            <Close className="w-6 h-6 my-3 fill-white" />
                        ) : (
                            <Menu className="w-7 h-7 my-2 fill-white" />
                        ) }
                    </button>
                    { menuLinks.map((link, index) => (
                        <MenuLink key={ index } to={ link.link } isVisible={ menuOpen }>
                            { link.label }
                        </MenuLink>
                    )) }
                </nav>
            </div>
            {   pathname.includes('erre-forditjuk') &&
                (<div className="w-full md:container md:mx-auto px-4 bg-[#005c8a] md:bg-transparent text-white">
                    <nav aria-label="Erre fordítjuk almenü"
                        className="container mx-auto flex flex-wrap md:flex-nowrap bg-[#005c8a]">
                            <span className="p-4 pl-2 md:pl-8 lg:pl-6 xl:pl-10">Archív:</span>
                            <SubMenuLink to="/erre-forditjuk-2022">2022</SubMenuLink>
                            <SubMenuLink to="/erre-forditjuk-2021">2021</SubMenuLink>
                            <SubMenuLink to="/erre-forditjuk-2020">2020</SubMenuLink>
                            <SubMenuLink to="/erre-forditjuk-2019">2019</SubMenuLink>
                            <SubMenuLink to="/erre-forditjuk-2018">2018</SubMenuLink>
                        </nav>
                </div>) }
        </header>
)}

Header.propTypes = {
    menuLinks: PropTypes.array.isRequired,
}

Header.defaultProps = {
    menuLinks: [],
}

export default Header
