import * as React from 'react'
import { Link } from 'gatsby'

import Hand from '../../images/kez.svg'
import Questionmark from '../../images/kerdojel.svg'
import Dog from '../../images/kutya.svg'
import Info from '../../images/info.svg'
import Logo from '../../images/easzlogo.svg'

const Bottom = () => {

    const linkClass = `w-full sm:w-1/2 md:w-1/5 flex flex-col space-y-4
        justify-start items-center p-4 text-center group
        hover:underline underline-offset-4`
    const circleClass = `flex justify-center content-center
        w-36 h-36 p-1.5 md:w-20 md:h-20 lg:w-32 lg:h-32 xl:w-36 xl:h-36
        border-8 md:border-4 lg:border-8
        border-gray-200 rounded-full text-white bg-[#86C3E2]
        hover:bg-sky-200 group-hover:bg-sky-200`
    const svgClass = `m-3 md:m-2 lg:m-3 fill-white`

    return (
        <footer className="my-6">
            <nav
                aria-label="Támogatói linkek"
                className="flex flex-wrap md:flex-nowrap justify-between bg-white uppercase md:text-sm"
            >
                <a href="https://noetklub.hu/" target="_blank" rel="noreferrer"
                    className={ linkClass }>
                    <div className={ circleClass } role="presentation">
                        <Hand className={ svgClass } />
                    </div>
                    <span>Noé Támogatói Klub<br />Csatlakozz!</span>
                </a>
                <Link to="/hogyan-tamogathat" className={ linkClass }>
                    <div className={ circleClass } role="presentation">
                        <Questionmark className={ svgClass } />
                    </div>
                    <span>Hogyan támogathatsz?</span>
                </Link>
                <a href="https://webshop.keverekkutya.hu/" target="_blank" rel="noreferrer"
                    className={ linkClass }>
                    <div className={ circleClass }>
                        <Dog className={ svgClass } />
                    </div>
                    <span>Ha vásárolsz, segítesz!</span>
                </a>
                <a href="http://noeallatotthon.hu/index.php?pid=2&cid=8" target="_blank" rel="noreferrer"
                    className={ linkClass }>
                    <div className={ circleClass }>
                        <Info className={ svgClass } />
                    </div>
                    <span>Információ önkénteseknek</span>
                </a>
                <a href="http://noeallatotthon.hu/etikus" target="_blank" rel="noreferrer"
                    className={ linkClass }>
                    <div className={ circleClass }>
                        <Logo className={ svgClass } />
                    </div>
                    <span>Etikus adománygyűjtő szervezet</span>
                </a>
            </nav>
        </footer>
    )
}

export default Bottom
