import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { LightboxContext } from './lightbox-page-layout'
import { ImageResources, ImageNodes } from '../types'

type GalleryProps = {
    resources: ImageResources,
    nodes: ImageNodes,
}
const Gallery: React.FC<GalleryProps> = ({ resources, nodes }) =>  {
    const [isSafari, setIsSafari] = React.useState(false)
    /* safari fix */
    React.useEffect(() => {
        const checkIsSafari = navigator.userAgent.indexOf("Safari") != -1
        setIsSafari(checkIsSafari)
    }, [])

    const imageClass = `border-2 border-transparent rounded-3xl md:rounded-lg lg:rounded-2xl xl:rounded-3xl
        aspect-square` + (isSafari ? '' : ' group-hover:border-blue-300')
    return (
        <div className="flex flex-wrap mx-0 md:-mx-2 lg:-mx-3 xl:-mx-4">            
            { resources.map((resource, index) => {
                const image = nodes.filter(({ base }) => base == resource.image.base)[0]
            
                return (
                    <LightboxContext.Consumer key={ index }>
                        { ({ setIsVisible, setSelectedIndex }) => {
                            const onButtonClick = (index: number) => {
                                if (isSafari) {
                                    return
                                }
                                if (setIsVisible) {
                                    setIsVisible(true)
                                }
                                if (setSelectedIndex) {
                                    setSelectedIndex(index)
                                }
                            }
                            return (<button onClick={ () => onButtonClick(resource.index!) }
                            className="w-full md:w-1/3 my-4 md:my-0 p-0 md:p-2 lg:p-3 xl:p-4 group"                                
                            aria-label={ 'Kép megnyitása nagyobb méretben: ' + resource.title }
                        >
                            <GatsbyImage
                                image={ image.childImageSharp.gatsbyImageData }
                                alt={ resource.title }
                                className={ imageClass }
                            />
                        </button>)
                    } }
                    </LightboxContext.Consumer>
                )
            }) }
        </div>
)}

export default Gallery