import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Gallery from "../../../../src/components/layout/gallery";
import Youtube from "../../../../src/components/layout/youtube";
import thumbData from "../../../../src/images/erreforditjuk/video/data.json";
import LightboxPageLayout from "../../../../src/components/layout/lightbox-page-layout";
import * as React from 'react';
export default {
  graphql,
  GatsbyImage,
  getImage,
  Gallery,
  Youtube,
  thumbData,
  LightboxPageLayout,
  React
};