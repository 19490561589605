import * as React from 'react'
import { Link } from 'gatsby'

import Home from '../../images/home.svg'
import Facebook from '../../images/facebook.svg'

type MenuLink = {
    label: string,
    link: string,
}
type FooterProps = {
    menuLinks: MenuLink[]
}
const Footer: React.FC<FooterProps> = ({ menuLinks }) => (
    <footer
        className="py-4 text-base md:text-xs uppercase
		bg-[#0589c9] border-t-8 border-[#00648C] text-white"
    >
        <div className="container mx-auto px-4">
            <nav className="flex flex-wrap justify-left -mx-2">
                { menuLinks.map((link, index) => (
                    <Link
                        className="w-full md:w-auto px-2 leading-10 md:leading-loose
						hover:underline underline-offset-4"
                        key={ index }
                        to={ link.link }
                    >
                        { link.label }
                    </Link>
                )) }
            </nav>
            <div className="flex flex-wrap justify-between content-center mt-4">
                <p className="w-full md:w-auto md:my-auto">
                    &copy; Noé állatotthon alapítvány 2022 - Budapest XVII. Ker.
                    Csordakút utca
                </p>
                <div className="w-full md:w-auto flex justify-end space-x-4 mt-4 md:mt-0">
                    <a
                        href="https://noeallatotthon.hu"
                        target="_blank"
                        className="flex justify-center content-center w-11 h-11 p-1.5
							border-2 border-white rounded-full hover:bg-white/10"
                        rel="noreferrer"
                        aria-label="Noé Állatotthon"
                    >
                        <Home className="fill-white stroke-white" />
                    </a>
                    <a
                        href="https://www.facebook.com/noeallatotthon"
                        target="_blank"
                        className="flex justify-center content-center w-11 h-11 p-2
							border-2 border-white rounded-full hover:bg-white/10"
                        rel="noreferrer"
                        aria-label="Facebook"
                    >
                        <Facebook className="fill-white" />
                    </a>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
