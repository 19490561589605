import * as React from 'react'
import PropTypes from 'prop-types'
import { PageProps } from 'gatsby'

import Layout from './layout'
import Seo from './seo'
import Lightbox from './lightbox'
import { ImageResources, ImageNodes } from '../types'

type ContextType = {
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>> | null,
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>> | null,
}
export const LightboxContext = React.createContext<ContextType>({ setIsVisible: null, setSelectedIndex: null })

type PageContextProps = {
    frontmatter: {
        title: string,
        path: string,
        date: string,      
    }
}
type DataProps = {
    allFile: {
        nodes: ImageNodes,
    },
    allJson: {
        nodes: [
            {
                resources: ImageResources
            },
        ],
    }
}
const LightboxPageLayout: React.FC<PageProps<DataProps, PageContextProps>> = ({ children, pageContext: { frontmatter }, data: { allFile: { nodes }, allJson } }) => {

    const [isVisible, setIsVisible] = React.useState(false)
    const [selectedIndex, setSelectedIndex] = React.useState(0)

    const onClose = () => {
        setIsVisible(prevState => !prevState)
    }
    const onPrevious = () => {
        setSelectedIndex(prevState => (prevState > 0) ? prevState - 1 : allJson.nodes[0].resources.length - 1)
    }
    const onNext = () => {
        setSelectedIndex(prevState => (prevState < allJson.nodes[0].resources.length - 1) ? prevState + 1 : 0)
    }

    return (
        <Layout>
            <Seo title={ frontmatter.title } />
            <div className="my-6 p-8 bg-white">
                <article className="prose md:prose-sm max-w-none
                    prose-h1:text-[#077ab3] prose-h1:uppercase prose-h1:font-bold prose-h2:text-[#87c2e1]
                    prose-h3:text-base md:prose-h3:text-base prose-p:text-justify
                    prose-a:text-[#0088cc] prose-a:font-bold prose-a:underline-offset-2 hover:prose-a:text-[#005580]">
                    <LightboxContext.Provider value={ { setIsVisible, setSelectedIndex } }>{ children }</LightboxContext.Provider>
                </article>
            </div>
            <Lightbox
                isOpen={ isVisible }
                onClose={ onClose }
                onPrevious={ onPrevious }
                onNext={ onNext }
                resources={ allJson.nodes[0].resources }
                nodes={ nodes }
                selectedIndex={ selectedIndex }
            />
        </Layout>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default LightboxPageLayout
