import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { YTResources, ImageNodes } from '../types'

type YoutubeProps = {
    resources: YTResources,
    nodes: ImageNodes,
}
const Youtube: React.FC<YoutubeProps> = ({ resources, nodes }) => {
    return (
    <>
        <div id="youtube-cookie-warning" className="w-full py-4 text-center">
            <p className="my-2">Videók megtekintéséhez engedélyezd a Yotube sütiket!</p>
            <p className="my-2">A sütik kezelését a bal alsó sarokban lévő sütis ikonnal tudod elérni.</p>
            <p className="my-2">A képre klikkelve a Youtube oldalán tudsz megnyitni videót.</p>
            {
                resources.map((resource, index) => {
                    const image = nodes.filter(({ base }) => base == resource.image)[0]
                    return (
                        <a className="block my-4" key={ index }
                            href={ 'https://youtu.be/' + resource.url }
                            aria-label={ 'Videó megnyitása youtube-on: ' + resource.title }
                            target="_blank" rel="noreferrer">
                            <GatsbyImage image={ image.childImageSharp.gatsbyImageData } alt="" />
                        </a>
                    )
                })
            }
        </div>
        {
            resources.map((resource, index) => (
                <iframe className="youtube hidden w-full my-4 aspect-video"
                    data-src={ 'https://www.youtube.com/embed/' + resource.url }
                    frameBorder="0" key={ index }
                    allowFullScreen></iframe>
            ))
        }
    </>
)}

export default Youtube